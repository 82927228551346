import React, { useEffect, useRef, useState } from "react";
import "./volunteer.css";
import ScrollReveal from "scrollreveal";
import { Link, useNavigate } from "react-router-dom";
import { TiTick } from "react-icons/ti";

import {
  Modal,
  Button,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  ButtonGroup,
} from "@chakra-ui/react";

function Volunteer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [pet, setPet] = useState("");
  const [service, setService] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [duration, setHours] = useState("");
  const [token, setToken] = useState("");

  useEffect(() => {
    let localToken = localStorage.getItem("token");
    setToken(localToken);
  }, []);

  useEffect(() => {
    const sr = ScrollReveal();

    sr.reveal("#s1", {
      delay: 75,
      duration: 1500,
      opacity: 0,
      distance: "50%",
      origin: "left",
      reset: true,
    });

    sr.reveal("#s2", {
      delay: 100,
      duration: 1500,
      opacity: 0,
      distance: "50%",
      origin: "right",
      reset: true,
    });

    sr.reveal("#s3", {
      delay: 100,
      duration: 1500,
      opacity: 0,
      distance: "50%",
      origin: "left",
      reset: true,
    });

    sr.reveal("#s4", {
      delay: 75,
      duration: 1500,
      opacity: 0,
      distance: "50%",
      origin: "bottom",
      reset: true,
    });
  }, []);

  const handleSubmit = () => {
    const details = {
      pet,
      type_of_service: service,
      startDate,
      endTime: endDate,
      duration,
    };
    if (token) {
      console.log(details);
      fetch("https://tiny-red-armadillo-cape.cyclic.cloud/service/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(details),
      })
        .then((res) => res.json())
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
      //onClose();
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="volunteer-main-container">
      {/* Header */}
      <div className="banner">
        <div className="banner-content">
          <h2>Why Volunteer With Us</h2>
          <p>
            We offer a range of volunteering opportunities that fit your interests.
            You'll be part of a community committed to making a positive change.
          </p>
        </div>
      </div>
      <div>
        <div className="volunteer-container">
          {/* Main Content */}
          {/* Section 1 */}
          <section className="section" id="s1">
            <div className="img1"></div>
            <article>
              <h2>Wagging tails:</h2>
              <p>
              You directly improve the quality of life for animals in need by providing care, 
              comfort, and socialization.
              </p>
              {/* <button onClick={onOpen} className="boarding">
                Book Pets Boarding
              </button> */}
            </article>
          </section>

          <section className="section" id="s2">
            <article>
              <h2>Emotional Fulfilment</h2>
              <p>
                It's emotionally gratifying to know you’re making a positive difference, building 
                bonds with animals, and helping them find forever homes.
              </p>
              {/* <button className="adopt">
                <Link to="/pets">Adopt A Pet</Link>
              </button> */}
            </article>
            <div className="img2"></div>
          </section>
          {/* End Section 2 */}

          {/* Section 3 */}
          <section className="section" id="s3">
            <div className="img3"></div>
            <article>
              <h2> Stress Relief</h2>
              <p>
                Spending time with animals can reduce stress and improve your mood, 
                offering emotional and mental health benefits.
              </p>
              {/* <button className="training" onClick={onOpen}>
                Book Slot
              </button> */}
            </article>
          </section>
          {/* End Section 3 */}
        </div>
          {/* Footer */}
          <div className="knowmore" id="s4">
            <h2>volunteers at Kolkata!</h2>
            <div className="knowmore-container">
              <div className="knowmore-item">
                <div>
                  <TiTick />
                </div>
                <div className="item-desc">
                  <h3>Animal Rescue And Care-Kolkata - 078908 38383</h3>
                  {/* <p>
                    Animal Rescue And Care-Kolkata - 078908 38383
                  </p>
                  <p>
                    Love N Care for Animals- 098300 37693
                  </p>
                  <p>
                    Garia Niramoy Foundation- 0861-7785873
                  </p>
                  <p>
                    Animal People Alliance Kolkata-090515 41112
                  </p>
                  <p>
                    Like a Dog -9831885229
                  </p> */}
                </div>
              </div>
              <div className="knowmore-item">
                <div>
                  <TiTick />
                </div>
                <div className="item-desc">
                  <h3>Love N Care for Animals- 098300 37693</h3>
                </div>
              </div>
              <div className="knowmore-item">
                <div>
                  <TiTick />
                </div>
                <div className="item-desc">
                  <h3>Garia Niramoy Foundation- 0861-7785873</h3>
                </div>
              </div>
              <div className="knowmore-item">
                <div>
                  <TiTick />
                </div>
                <div className="item-desc">
                  <h3>Animal People Alliance Kolkata-090515 41112</h3>
                </div>
              </div>
              <div className="knowmore-item">
                <div>
                  <TiTick />
                </div>
                <div className="item-desc">
                  <h3>Like a Dog -9831885229</h3>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
}

export default Volunteer;
