import React, { useEffect, useRef, useState } from "react";
import "./about.css";
import ScrollReveal from "scrollreveal";
import { Link, useNavigate } from "react-router-dom";
import { TiTick } from "react-icons/ti";

import {
  Modal,
  Button,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  ButtonGroup,
} from "@chakra-ui/react";

function AboutUs() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [pet, setPet] = useState("");
  const [service, setService] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [duration, setHours] = useState("");
  const [token, setToken] = useState("");

  useEffect(() => {
    let localToken = localStorage.getItem("token");
    setToken(localToken);
  }, []);

  useEffect(() => {
    const sr = ScrollReveal();

    sr.reveal("#s1", {
      delay: 75,
      duration: 1500,
      opacity: 0,
      distance: "50%",
      origin: "left",
      reset: true,
    });

    sr.reveal("#s2", {
      delay: 100,
      duration: 1500,
      opacity: 0,
      distance: "50%",
      origin: "right",
      reset: true,
    });

    sr.reveal("#s3", {
      delay: 100,
      duration: 1500,
      opacity: 0,
      distance: "50%",
      origin: "left",
      reset: true,
    });

    sr.reveal("#s4", {
      delay: 75,
      duration: 1500,
      opacity: 0,
      distance: "50%",
      origin: "bottom",
      reset: true,
    });
  }, []);

  const handleSubmit = () => {
    const details = {
      pet,
      type_of_service: service,
      startDate,
      endTime: endDate,
      duration,
    };
    if (token) {
      console.log(details);
      fetch("https://tiny-red-armadillo-cape.cyclic.cloud/service/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(details),
      })
        .then((res) => res.json())
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
      //onClose();
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="aboutus-main-container">
      {/* Header */}
      <div className="banner1">
        <div className="banner-content1">
          <h2>About ThePaws&Claws Project</h2>
          <p>
            We help pets live healthy, happy lives while 
            supporting their families with love and care.
          </p>
        </div>
      </div>
      {/* <div> */}
        <div className="aboutus-container">
          {/* Main Content */}
          {/* Section 1 */}
          <section className="section" id="s1">
            <div className="img1a"></div>
            <article>
              <h2>About Project</h2>
              <p>
                Hello! My name is Hunar Choudhary , a 17-year-old dreamer , and this is my best friend Polo. 
                Ever since his tiny pink paws left their footprints in my heart , I have envisioned creating 
                a platform that could connect loving families with strays in need of a forever home. 1,50,000 is 
                just a number until you realise that it is the amount of hungry stomachs , sunken eyes and dejected 
                hearts  roaming kolkata’s streets everyday. The Paw Project’s mission is to rescue,  rehabilitate, 
                and rehome dogs of all breeds and backgrounds, ensuring that every pup finds a safe and caring environment. 
              </p>
              {/* Join us in our journey to make a difference, one wagging tail at a time. Remember, for us , 
                our pets might just be a part of our lifetime but we are their whole. */}
              {/* <button onClick={onOpen} className="boarding">
                Book Pets Boarding
              </button> */}
            </article>
          </section>

          <section className="section" id="s2">
            <article>
              <h2>About Pet Adoption</h2>
              <p>
                Welcome to our Pet Adoption Center, where hearts and paws
                connect. Discover a diverse range of lovable animals eagerly
                waiting to join your family. Our adoption process is designed to
                ensure the perfect match, with each pet fully vaccinated and
                vetted. Give a forever home and experience the joy of adoption
                today..
              </p>
              {/* <button className="adopt">
                <Link to="/pets">Adopt A Pet</Link>
              </button> */}
            </article>
            <div className="img2a"></div>
          </section>
          {/* End Section 2 */}

          {/* Section 3 */}
          <section className="section" id="s3">
            <div className="img3a"></div>
            <article>
              <h2> Join us</h2>
              <p>
                Join us in our journey to make a difference, one wagging tail at a time. Remember, 
                for us , our pets might just be a part of our lifetime but we are their whole.
              </p>
              {/* <button className="training" onClick={onOpen}>
                Book Slot
              </button> */}
            </article>
          </section>
          {/* End Section 3 */}
        </div>
      {/* </div> */}
    </div>
  );
}

export default AboutUs;
