import React, { useEffect, useRef, useState } from "react";
import "./services.css";
import ScrollReveal from "scrollreveal";
import { Link, useNavigate } from "react-router-dom";
import { TiTick } from "react-icons/ti";

import {
  useDisclosure,
} from "@chakra-ui/react";

function Services() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [pet, setPet] = useState("");
  const [service, setService] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [duration, setHours] = useState("");
  const [token, setToken] = useState("");

  useEffect(() => {
    let localToken = localStorage.getItem("token");
    setToken(localToken);
  }, []);

  useEffect(() => {
    const sr = ScrollReveal();

    sr.reveal("#s1", {
      delay: 75,
      duration: 1500,
      opacity: 0,
      distance: "50%",
      origin: "left",
      reset: true,
    });

    sr.reveal("#s2", {
      delay: 100,
      duration: 1500,
      opacity: 0,
      distance: "50%",
      origin: "right",
      reset: true,
    });

    sr.reveal("#s3", {
      delay: 100,
      duration: 1500,
      opacity: 0,
      distance: "50%",
      origin: "left",
      reset: true,
    });

    sr.reveal("#s4", {
      delay: 75,
      duration: 1500,
      opacity: 0,
      distance: "50%",
      origin: "bottom",
      reset: true,
    });
  }, []);

  const handleSubmit = () => {
    const details = {
      pet,
      type_of_service: service,
      startDate,
      endTime: endDate,
      duration,
    };
    if (token) {
      console.log(details);
      fetch("https://tiny-red-armadillo-cape.cyclic.cloud/service/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(details),
      })
        .then((res) => res.json())
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
      //onClose();
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="services-main-container">
      {/* Header */}
      <div className="banner3">
        <div className="banner-content3">
          <h2>Let’s have a conversation!</h2>
          <p>
            Need assistance or have questions? At ThePawsandClaws, we're here to help! Reach out for 
            any inquiries, pet care advice, or any support you need. 
            We're just a message away!
          </p>
        </div>
      </div>
      <div>
        <div className="services-container">
          {/* Main Content */}
          
          {/* Footer */}
          <div className="knowmore" id="s4">
            <h2>Where to contact!</h2>
            <div className="knowmore-container">
              <div className="knowmore-item">
                <div>
                  <TiTick />
                </div>
                <div className="item-desc">
                  <h3>How can I contact your team through mobile or email?</h3>
                  <p>
                  You can reach us by calling our mobile number at +91 70035 99849 or by sending an email to hunarc12@gmail.com. 
                  We strive to respond to all inquiries as quickly as possible.
                  </p>
                </div>
              </div>
              <div className="knowmore-item">
                <div>
                  <TiTick />
                </div>
                <div className="item-desc">
                  <h3>Do you accept mobile calls during office hours?</h3>
                  <p>
                  Yes, we accept mobile calls during our office hours, which are 10:00 AM to 06:00 PM. 
                  Feel free to give us a call during these times for any assistance you may need.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
