import React, { useEffect, useRef, useState } from "react";
import "./faq.css";
import ScrollReveal from "scrollreveal";
import { Link, useNavigate } from "react-router-dom";
import { TiTick } from "react-icons/ti";

import {
  useDisclosure,
} from "@chakra-ui/react";

function FAQ() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [pet, setPet] = useState("");
  const [service, setService] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [duration, setHours] = useState("");
  const [token, setToken] = useState("");

  useEffect(() => {
    let localToken = localStorage.getItem("token");
    setToken(localToken);
  }, []);

  useEffect(() => {
    const sr = ScrollReveal();

    sr.reveal("#s1", {
      delay: 75,
      duration: 1500,
      opacity: 0,
      distance: "50%",
      origin: "left",
      reset: true,
    });

    sr.reveal("#s2", {
      delay: 100,
      duration: 1500,
      opacity: 0,
      distance: "50%",
      origin: "right",
      reset: true,
    });

    sr.reveal("#s3", {
      delay: 100,
      duration: 1500,
      opacity: 0,
      distance: "50%",
      origin: "left",
      reset: true,
    });

    sr.reveal("#s4", {
      delay: 75,
      duration: 1500,
      opacity: 0,
      distance: "50%",
      origin: "bottom",
      reset: true,
    });
  }, []);

  const handleSubmit = () => {
    const details = {
      pet,
      type_of_service: service,
      startDate,
      endTime: endDate,
      duration,
    };
    if (token) {
      console.log(details);
      fetch("https://tiny-red-armadillo-cape.cyclic.cloud/service/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(details),
      })
        .then((res) => res.json())
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
      //onClose();
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="faq-main-container">
      {/* Header */}
      <div className="banner2">
        <div className="banner-content2">
          <h2>Do You Have Questions?</h2>
          <p>
            Bellow you'll find answers to the most common questions you may have.
            If you still can't find the answer you're looking for, just Contact Us!
          </p>
        </div>
      </div>
      <div>
        <div className="faq-container">
          {/* Main Content */}
          
          {/* Footer */}
          <div className="knowmore" id="s4">
            <h2>Ask us anything!</h2>
            <div className="knowmore-container">
              <div className="knowmore-item">
                <div>
                  <TiTick />
                </div>
                <div className="item-desc">
                  <h3>How much does an adoption cost? </h3>
                  <p>
                    The cost of adopting a dog in can range from ₹2,000 to ₹8000, depending on factors like the shelter, 
                    breed, and included services such as vaccinations or spaying/neutering.
                  </p>
                </div>
              </div>
              <div className="knowmore-item">
                <div>
                  <TiTick />
                </div>
                <div className="item-desc">
                  <h3>Dog sitters you can trust</h3>
                  <p>
                    Every sitter profile has been individually reviewed and
                    approved by Rover and sitters on Rover have received
                    millions of 5-star reviews.
                  </p>
                </div>
              </div>
              <div className="knowmore-item">
                <div>
                  <TiTick />
                </div>
                <div className="item-desc">
                  <h3>Is there a health guarantee for the adopted pets? </h3>
                  <p>
                    Yes, adopted pets include a health guarantee, with all known medical 
                    conditions fully disclosed before adoption.
                  </p>
                </div>
              </div>
              <div className="knowmore-item">
                <div>
                  <TiTick />
                </div>
                <div className="item-desc">
                  <h3>Can I adopt a dog even if I’m younger than 18?</h3>
                  <p>
                    No, unfortunately, adoption isn't possible for those under 18, as official ownership 
                    cannot be transferred to minors. However, you could consider fostering or adopting 
                    with a guardian's help.
                  </p>
                </div>
              </div>
              <div className="knowmore-item">
                <div>
                  <TiTick />
                </div>
                <div className="item-desc">
                  <h3>Do you visit my home as a part of the adoption process?</h3>
                  <p>
                    No, home visits are not typically part of the adoption process. However, 
                    certain official documents may be required to verify that the adopter is 
                    suitable and capable of providing appropriate care for the pet.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
